import React from 'react';
import styled from 'styled-components';

import { BlogPostPreviewList } from 'components/Blog';
import { SEO } from 'components/UI';
import {
	TABLET_GRID_SIZE,
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING
} from 'consts';
import seo from 'data/seo.json';
import content from 'data/thanks.json';
import { MediaQueries, Sizes } from 'environment';
import { useQueryContext } from 'hooks';

export default function Thanks() {
	const { posts } = useQueryContext();

	return (
		<Container>
			<SEO title={seo.thanksTitle} ogTitle={seo.thanksOgTitle} />
			<Content>
				<Title>{content.title}</Title>
				{content.text.map((t, i) => (
					<Text key={`thanks-message-${i}`}>{t}</Text>
				))}
				<Posts>
					<BlogPostPreviewList posts={posts.slice(0, 3)} title={content.blog} />
				</Posts>
			</Content>
		</Container>
	);
}

const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 4rem;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		align-items: center;
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

const Posts = styled.div`
	padding: 12rem 0;

	@media ${MediaQueries.phone} {
		padding: 6rem 0;
	}
`;

const Title = styled.h1`
	margin-bottom: 2rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
		margin-bottom: 1rem;
	}
`;

const Text = styled.p`
	max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
	text-align: center;

	:not(:first-of-type) {
		margin-top: 3rem;
	}
`;
